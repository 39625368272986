<template>
  <div>
    <vx-card>
       <vs-row vs-type="flex" vs-justify="space-between" >
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
          <label class="vs-input--label mr-2">Marca</label>
          <v-select                                               
            :options="CONSTANTE_MARCAS_MODULO" 
            v-model="filtro.marca"                      
            class="w-full vs-input--label">
          </v-select> 
          <label class="vs-input--label mr-2">Modelo</label>
          <vs-input class="mb-4 md:mb-0 w-full" v-model="filtro.tipoModulo" @keyup.enter="fetchLista(filtro)"/>          
          <label class="vs-input--label mr-2">Codigo</label>
          <vs-input class="mb-4 md:mb-0 w-full" v-model="filtro.codigo"  @keyup.enter="fetchLista(filtro)" />
          <label class="vs-input--label mr-2">Status</label>
          <v-select                                               
            :options="CONSTANTE_STATE_MODULO" 
            v-model="filtro.stateModulo"                      
            class="w-full vs-input--label">
          </v-select>                  
        </vs-col>
      </vs-row>
      <vs-row vs-type="flex" vs-justify="space-between" class="mt-2">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
          
          <label class="vs-input--label mr-2">Serial</label>
          <vs-input class="mb-4 md:mb-0 w-full" v-model="filtro.serial" @keyup.enter="fetchLista(filtro)" />
          <label class="vs-input--label mr-2">Placa</label>
          <vs-input class="mb-4 md:mb-0 w-full" v-model="filtro.placaVeiculo" @keyup.enter="fetchLista(filtro)"/>
          <label class="vs-input--label mr-2">Técnico</label>
          <vs-input class="mb-4 md:mb-0 w-full" v-model="filtro.nomeTecnico" @keyup.enter="fetchLista(filtro)"/>
          <label class="vs-input--label mr-2">Número GSM</label>
          <vs-input class="mb-4 md:mb-0 w-full" v-model="filtro.numeroGSM" @keyup.enter="fetchLista(filtro)"/>
          <label class="vs-input--label mr-2" style="white-space:nowrap">Operadora GSM</label>              
          <v-select                                               
                :options="CONSTANTE_OPERADORAS_GSM" 
                :reduce="r => r.data"                    
                v-model="filtro.operadoraGSM"  
                class="w-full vs-input--label">
            </v-select>  
        </vs-col>
      </vs-row>
     
      <vs-row vs-type="flex" vs-justify="space-between" class="mt-2">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="7">
         <v-select                                               
            :options="CONSTANTE_TIPO_CLIENTE_PROPRIETARIO" 
            v-model="filtro.tipoClienteProprietario"  
            :clearable="false"       
            style="min-width: 160px"             
            class="vs-input--label">
          </v-select>
           <v-select  
                    label="nome"                                             
                    :reduce="r => r.id"
                    :options="listaClientes" 
                    v-model="filtro.idClienteProprietario"                      
                    class="w-full vs-input--label">
                </v-select>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3">
          <vs-checkbox v-model="filtro.semSimcard" class="w-full"><small>Sem simcard</small></vs-checkbox>                            
          <vs-checkbox v-model="filtro.emVeiculos" class="w-full"><small>Em Veículos</small></vs-checkbox>                            
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
          <vs-button class="mr-2" color="primary" type="filled" icon-pack="feather" icon="icon-search" @click="fetchLista(filtro)"></vs-button>
          <vs-button class="mr-2" color="dark" type="border" icon-pack="feather" icon="icon-file" @click="limparFiltros"></vs-button>  
          <vs-button class="mr-2" color="warning" type="filled" icon-pack="feather" icon="icon-plus" @click="routeNovoRegistro"></vs-button>          
          <vs-button color="rgb(11, 189, 135)" type="filled" icon-pack="feather" icon="icon-save" @click="exportarParaCSV"></vs-button>          
          <vs-button class="ml-2" color="warning" type="filled" icon-pack="feather" icon="icon-list" @click="routeImportarModulos"></vs-button>          
        </vs-col>
      </vs-row>
      
    </vx-card>

    <p class="text-danger mt-2 text-sm"> {{dadosDaGrid.length }} registros </p>
     
    <ag-grid-vue
      ref="agGridTable"           
      :gridOptions="configuracaoGrid.gridOptions"
      class="ag-theme-material ag-grid-table ag-grid-altura mt-1"
      :columnDefs="configuracaoGrid.columnDefs"
      :defaultColDef="configuracaoGrid.defaultColDef"
      :rowData="dadosDaGrid"
      :rowHeight="35"
      :headerHeight="25"        
      rowSelection="single"
      colResizeDefault="shift"
      @row-selected="onRowSelectedRegistro"    
      :animateRows="true"
      :floatingFilter="false"
      :pagination="false"
      :paginationAutoPageSize="false"
      :suppressPaginationPanel="true"
      >
    </ag-grid-vue>      
    
  </div>

</template>

<script>
import axios from "@/axios.js"
import { AgGridVue } from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import { CONSTANTE_STATE_MODULO } from '@/constantesComboBox.js'
import { CONSTANTE_MARCAS_MODULO } from '@/constantesComboBox.js'
import { CONSTANTE_TIPO_CLIENTE_PROPRIETARIO } from '@/constantesComboBox.js'
import { CONSTANTE_OPERADORAS_GSM } from '@/constantesComboBox.js'

const URL_SERVLET = "/ModulosRest";
const NAME_ROUTE_EDIT = "cadastro-modulos-editar";

export default {
  components: {
    AgGridVue
  },
  data() {
    return {
      filtro : {codigo: null, serial:  null, placaVeiculo: null, nomeTecnico: null, numeroGSM: null, tipoModulo: null, 
      marca: null, tipoClienteProprietario: 'CLIENTE', stateModulo: null, semSimcard: false, emVeiculos: false, idClienteProprietario: null, operadoraGSM: null},
      configuracaoGrid: { 
          gridApi: null, gridOptions: {}, 
          defaultColDef: { sortable: true, resizable: true, suppressMenu: true  },
          columnDefs: [        
            { headerName: "#", valueGetter: "node.rowIndex + 1", width: 30,  cellStyle: function() { return {'font-size': '10px'}; }  },
            { headerName: 'Código', field: 'codigo' , width: 120},           
            { headerName: 'Marca', field: 'marca', width: 130 },           
            { headerName: 'Serial', field: 'serial', width: 120 },           
            { headerName: 'Modelo', field: 'tipo', width: 130,
             cellRenderer: function(params) {
                return params.data.tipo + ( params.data.hasOwnProperty('versaoFirmware') && params.data.versaoFirmware ?  '<br/> <span style="font-size: 10px; color:orange"> fw:' + params.data.versaoFirmware.toUpperCase() + '</span' : '');
              },
              valueGetter: function(params) {
                return params.data.tipo + ( params.data.hasOwnProperty('versaoFirmware') && params.data.versaoFirmware ?  ' - fw:' + params.data.versaoFirmware.toUpperCase() : '');
              } },           
            { headerName: 'Número GSM', field: 'numeroGSM', width: 100 },           
            { headerName: 'Operadora', field: 'operadoraGSM', width: 80 },           
            { headerName: 'Status', field: 'stateModulo', width: 100,            
              cellRenderer: function(params) {
                return params.data.stateModulo + (params.data.hasOwnProperty('idClienteProprietario') ? '<br/> <span style="font-size: 10px; color:orange"> DE TERCEIRO </span>' : '') ;
              },
              valueGetter: function(params) {
                return params.data.stateModulo;
              }
            },           
            { headerName: 'Comunicação', field: 'ultimaComunicacao', width: 90,
              cellRenderer: function(params) {
                return params.data.ultimaComunicacao + '<br/>' + params.data.ultimoServidorConectado;
              },
              valueGetter: function(params) {
                return params.data.ultimaComunicacao + ' - ' + params.data.ultimoServidorConectado;
              }
            },           
            { headerName: 'Informações', width: 100,
              cellRenderer: function(params) {
                  if (params.data.stateModulo === 'ATIVO') {
                    if (params.data.hasOwnProperty('placaVeiculo')) {
                      return params.data.placaVeiculo + '<br/>' + params.data.nomeClienteVeiculo;
                    } 
                  } else if (params.data.stateModulo === 'COM TECNICO') {
                    if (params.data.hasOwnProperty('nomeTecnico')) {
                      return params.data.nomeTecnico;
                    }  
                  } else if (params.data.stateModulo === 'DE TERCEIRO') {
                    if (params.data.hasOwnProperty('nomeClienteProprietario')) {
                      return params.data.nomeClienteProprietario;
                    }  
                  }  else if (params.data.stateModulo === 'COM CLIENTE') {
                    if (params.data.hasOwnProperty('nomeClienteComPosse')) {
                      return params.data.nomeClienteComPosse;
                    }  
                  }
                  
                  return ' ';
              },
              valueGetter: function(params) {
                  if (params.data.stateModulo === 'ATIVO') {
                    if (params.data.hasOwnProperty('placaVeiculo')) {
                      return params.data.placaVeiculo + ' - ' + params.data.nomeClienteVeiculo;
                    } 
                  } else if (params.data.stateModulo === 'COM TECNICO') {
                    if (params.data.hasOwnProperty('nomeTecnico')) {
                      return params.data.nomeTecnico;
                    }  
                  } else if (params.data.stateModulo === 'DE TERCEIRO') {
                    if (params.data.hasOwnProperty('nomeClienteProprietario')) {
                      return params.data.nomeClienteProprietario;
                    }  
                  }  else if (params.data.stateModulo === 'COM CLIENTE') {
                    if (params.data.hasOwnProperty('nomeClienteComPosse')) {
                      return params.data.nomeClienteComPosse;
                    }  
                  }
                  
                  return ' ';
              }
            },           
          ]
      },
      dadosDaGrid: [],
      totalRegistros: 0,
      listaClientes : [],     
    }
  },  
  created() {
      this.CONSTANTE_STATE_MODULO = CONSTANTE_STATE_MODULO;
      this.CONSTANTE_MARCAS_MODULO = CONSTANTE_MARCAS_MODULO;
      this.CONSTANTE_TIPO_CLIENTE_PROPRIETARIO = CONSTANTE_TIPO_CLIENTE_PROPRIETARIO;
      this.CONSTANTE_OPERADORAS_GSM = CONSTANTE_OPERADORAS_GSM;
  },
  methods: {
    limparFiltros() {
      this.filtro = {codigo: null, serial:  null, placaVeiculo: null, nomeTecnico: null, numeroGSM: null, tipoModulo: null, 
      marca: null, tipoClienteProprietario: 'CLIENTE', stateModulo: null, semSimcard: false, emVeiculos: false, idClienteProprietario: null};      
    },
    fetchLista(pFiltro) {     
      this.$vs.loading() 
      const params = new URLSearchParams();
      params.append('method', 'FindAll');
      params.append('orderDirection', 'ASC');
      params.append('outputFormat', 'JSON');
      
      if (pFiltro.codigo) {
        params.append('filter1', pFiltro.codigo);
      }

      if (pFiltro.marca) {
        params.append('filter2', pFiltro.marca.data);
      }

      if (pFiltro.serial) {
        params.append('filter', pFiltro.serial);
      }

      if (pFiltro.numeroGSM) {
        params.append('pNumeroGSM', pFiltro.numeroGSM);
      }

      if (pFiltro.stateModulo) {
        params.append('pStateModulo', pFiltro.stateModulo.data);
      }

      if (pFiltro.placaVeiculo) {
        params.append('placaVeiculo', pFiltro.placaVeiculo);
      }

      if (pFiltro.nomeTecnico) {
        params.append('nomeTecnico', pFiltro.nomeTecnico);
      }

      if (pFiltro.tipoModulo) {
        params.append('tipoModulo', pFiltro.tipoModulo);
      }

      params.append('semSimcard', pFiltro.semSimcard);
      params.append('emVeiculos', pFiltro.emVeiculos);

      if (this.filtro.idClienteProprietario) {
        params.append('tipoClienteProprietario', this.filtro.tipoClienteProprietario);
        params.append('idClienteProprietario', this.filtro.idClienteProprietario);
      }

       if (this.filtro.operadoraGSM) {
        params.append('operadoraGSM', this.filtro.operadoraGSM);
      }

      axios.post(URL_SERVLET, params, {              
              credentials: 'include',
              responseEncoding: 'iso88591',
              withCredentials: true } )
      .then((response) => {
          this.$vs.loading.close();
          if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
              this.$router.push({ path: '/' });
          } else {
              this.dadosDaGrid = response.data;         
              this.totalRegistros = this.dadosDaGrid.length;                              
          }
      })
      .catch((error) => { 
          this.$vs.loading.close();
          /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchListaPoI - " + error); 
      })    
                        
    },
    routeNovoRegistro() {
      let self_ = this;      
      self_.$router.push({
            name: NAME_ROUTE_EDIT                     
      })
    },
    routeImportarModulos() {      
      this.$router.push({
            name: "cadastro-modulos-importar"                     
      })
    },
    onRowSelectedRegistro(evento) {
      let self_ = this;
      let registro = evento.node.data;
      /* eslint-disable no-console */ console.log('registro=' + registro.hasOwnProperty('codigo'));      
      
      self_.$router.push({
            name: NAME_ROUTE_EDIT,
            params: {
              registroOriginal: registro
            }
      })

    },
    exportarParaCSV() {
      var params = {columnSeparator: ';', fileName: 'Modulos.csv'};
      this.configuracaoGrid.gridApi.exportDataAsCsv(params);
    },
    fetchListaClientes() {     
      this.$vs.loading() 
      const params = new URLSearchParams();
      params.append('method', 'FindAll');
      params.append('orderDirection', 'ASC');
      params.append('outputFormat', 'JSON');
      params.append('naoExibirInativos', 'true');
      
      axios.post("/ClientesRest", params, {              
              credentials: 'include',
              responseEncoding: 'iso88591',
              withCredentials: true } )
      .then((response) => {
          this.$vs.loading.close();
          if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
              this.$router.push({ path: '/' });
          } else {
              this.listaClientes = response.data;         
          }
      })
      .catch((error) => { 
          this.$vs.loading.close();
          /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
      })    
                        
    }, 
    
  },
  mounted() {
    this.configuracaoGrid.gridApi = this.configuracaoGrid.gridOptions.api;
    this.configuracaoGrid.gridApi.sizeColumnsToFit();    
    this.fetchListaClientes();
    if (this.$store.getters.filtroCadastroModulos) {
      this.filtro = JSON.parse(JSON.stringify(this.$store.getters.filtroCadastroModulos));
    }
    this.fetchLista(this.filtro);
    
  },
   beforeDestroy() {    
        this.$store.dispatch('setFiltroCadastroModulos', this.filtro);
    }
}

</script>
<style lang="scss" scoped>
  .ag-grid-altura{
    height: calc(100vh - 295px);
  }
</style>